import React from "react"
import {
  ScreenContainer,
  TextSection,
  ViewButton,
  VideosWrapper,
  NewPageWrapper,
  Warning,
} from "./style"
import { PageWrapper } from "../../components/PageWrapper"
import { VideoTeaser } from "../VideoTeaser"
import { Icon } from "utils/getIcon"
import miniMeditation from "images/mini-meditation.png"
import kokorokara from "images/kokorokara.png"
import reimagining from "images/reimagining-masculinity-thumbnail.png"
import beyondSilence from "images/beyond-silence.png"

const VideoScreen = ({ data }) => {
  const arrow = <Icon name="arrow" />
  const warning = <Icon name="warning" />

  return (
    <PageWrapper>
      <ScreenContainer>
        <TextSection>
          {data.double ? (
            <TextSection>
              <h2>Remember, you're not alone!</h2>
              <p>
                Here are some stories from other people talking about their
                experiences.
              </p>
              <Warning>
                {warning}
                <p>
                  <span>Warning</span> Viewing these digital stories may be
                  upsetting or difficult. They may bring up some things for you
                  from your own experience. We suggest trying a{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank "
                    href="https://services.unimelb.edu.au/counsel/resources/guided-exercises"
                  >
                    grounding exercise
                  </a>{" "}
                  which is used by many who have lived experience of trauma to
                  aid connection to the present, to their body and to themselves
                  whenever trauma disconnects them. If you share an office or
                  are viewing these at home you may choose to use headphones.
                </p>
              </Warning>
            </TextSection>
          ) : data.signals ? (
            <TextSection>
              <h2>Whenever you feel any of these signals...</h2>
              <p>
                Take a moment. Remove yourself from the situation. Think about
                how you are feeling. Recognise the signs that mean you might be
                about to say or do something to hurt your partner.
              </p>
            </TextSection>
          ) : (
            <TextSection>
              <p>
                If going through this website is bring up some things for you,
                or you start to feel stressed or overwhelmed, a grounding
                exercise can sometimes help.
              </p>

              <p>
                Here’s a grounding actions video that might help and also some
                links to mental health and wellbeing exercises.
              </p>
            </TextSection>
          )}
          {!data.double && (
            <ViewButton
              label="View grounding exercises"
              link="https://services.unimelb.edu.au/counsel/resources/guided-exercises"
              noBackground
              icon={arrow}
            />
          )}
          {data.donwloadFile && (
            <ViewButton
              label="View winding down exercises"
              link="winding-yourself-up.docx"
              noBackground
              icon={arrow}
            />
          )}
        </TextSection>

        {data.double ? (
          <VideosWrapper>
            <VideoTeaser
              small
              url="https://www.youtube.com/watch?v=L368EhvX2Tk"
              title="Kokorokara  - one woman's story about recovery from sexual assault"
              img={kokorokara}
            />
            <VideoTeaser
              small
              url="https://www.youtube.com/watch?v=Xs29xt-jhkY"
              title="First Time Love - one woman’s reflection on the ‘little red flags’ in a relationship"
              img={beyondSilence}
            />
            <VideoTeaser
              small
              url="https://www.youtube.com/watch?v=BWWPZlaq35U"
              title="Reimagining masculinity; my journey as a male sexual assault survivor"
              img={reimagining}
            />
          </VideosWrapper>
        ) : (
          <VideoTeaser
            url="https://www.youtube.com/watch?v=EfubAj5f_rM"
            title="Mini Meditation - Let Go of Stress"
            img={miniMeditation}
          />
        )}
      </ScreenContainer>
    </PageWrapper>
  )
}

export default VideoScreen
